<template>
  <div>
    <v-app-bar :color="!$vuetify.theme.dark ? 'white' : ''" app flat>

      <v-app-bar-nav-icon @click="$vuetify.breakpoint.mobile ? $emit('mobileDrower') : $emit('miniVariant')" />

<!--      <v-btn v-if="can('admin | client')" :disabled="$route.name === 'campaigns.create' || can('supplier')"-->
<!--        color="primary" depressed rounded to="/campaigns/create">-->
<!--        <v-icon :left="!$vuetify.breakpoint.mobile">mdi-message-plus</v-icon>-->
<!--        {{ !$vuetify.breakpoint.mobile ? 'Nouvelle campagne' : '' }}-->
<!--      </v-btn>-->

      <shortcut />

      <v-btn v-if="can('admin')" icon to="/settings">
        <v-icon>mdi-cog-outline</v-icon>
      </v-btn>

      <v-spacer />


      <v-menu offset-y transition="scale-transition">

        <template v-slot:activator="{ on, attrs }">

          <v-btn class="mr-2" color="grey" rounded text v-bind="attrs"
                 v-on="on">
            <v-skeleton-loader v-if="$store.state.accountLoading" style="min-width: 100px;" type="text" />
            <span v-else>
              {{ CurrencyFormatting($store.state.user.user.account.all_balance) }} DZD</span>
              
          </v-btn>

        </template>
        <v-card class="rounded">

          <v-card-text class="pa-0" style="min-width: 200px" v-if="$store.state.user?.user?.account?.service_balances">
            <v-list>

              <v-list-item exact v-for="item in $store.state.user.user.account.service_balances " :key="item.id">
                <v-list-item-title>
                  
                  {{ item.service.name }} - <span class="pink--text darken-4">{{ CurrencyFormatting(item.amount) }} DZD</span>
                  {{ item.paied == 1? '(Payé)' : '(Crédit)' }}
                </v-list-item-title>
              </v-list-item>

            </v-list>
          </v-card-text>
        </v-card>
      </v-menu>

      <v-menu max-width="300" min-width="300" offset-y transition="scale-transition">

        <template v-slot:activator="{ on, attrs }">

          <v-btn class="text-none" icon v-bind="attrs" v-on="on">
            <v-badge bordered bottom color="success" dot offset-x="10" offset-y="10">
              <v-avatar size="40">
                <v-img v-if="$store.state.user.user.account.photo"
                  :src="getImageLink($store.state.user.user.account.photo)"></v-img>
                <v-img v-else :src="require('@/assets/avatar.png')"></v-img>
              </v-avatar>
            </v-badge>
          </v-btn>


        </template>
        <v-card>

          <v-card-title>
            <v-list-item two-line>
              <v-list-item-avatar size="50">
                <v-img v-if="$store.state.user.user.account.photo"
                  :src="getImageLink($store.state.user.user.account.photo)"></v-img>
                <v-img v-else :src="require('@/assets/avatar.png')"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ $store.state.user.user.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ $store.state.user.user.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card-title>

          <v-divider />

          <v-card-text class="pa-0">
            <v-list>

              <v-list-item exact to="/myAccount">
                <v-list-item-avatar>
                  <v-icon>mdi-account-circle-outline</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>Mon compte</v-list-item-title>
              </v-list-item>

              <v-list-item @click="logout">
                <v-list-item-avatar>
                  <v-icon>mdi-logout</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>Déconnexion</v-list-item-title>
              </v-list-item>

            </v-list>
          </v-card-text>
        </v-card>
      </v-menu>

      <v-progress-linear :active="loading" :indeterminate="loading" absolute bottom color="primary" />
    </v-app-bar>
  </div>
</template>
<script>
import { HTTP } from "@/http-common";
import Shortcut from "@/navigation/shortcut.vue";

export default {
  components: { Shortcut },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    logout() {
      this.loading = true
      HTTP.post('auth/logout').then(() => {
        this.loading = false
        this.$store.dispatch('user', '')
        this.$store.dispatch('isLoggedIn', false)
        this.$store.dispatch('stores', [])
        this.$router.go(0)
      }).catch(err => {
        this.loading = false
        console.log(err)
      })
    }
  }
}
</script>

<style scoped></style>